import React, { useState, useEffect } from "react";
import * as API from "../utils/utils";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect, walletlink } from "../wallet/connectors";
import { Spinner } from "./Spinner";
import Button from "../components/Button";
import { Country } from 'country-state-city';
import cap from "../images/cap-hoodie-01.png";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';

const ConnectorNames = {
    Injected: "Metamask",
    WalletConnect: "WalletConnect",
    WalletLink: "WalletLink",
};

const connectorsByName = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
    [ConnectorNames.WalletLink]: walletlink,
};

export default function Form({ token, setIsProcessingModalOpen }) {

    const context = useWeb3React();
    const { connector } = context;
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [tShirtSize, setTShirtSize] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [region, setRegion] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");

    const [submitted, setSubmitted] = useState(false);
    const [failure, setFailure] = useState(false);
    const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);
    const [error, setError] = useState("");

    const [xsmallStock, setXSmallStock] = useState(false);
    const [smallStock, setSmallStock] = useState(true);
    const [mediumStock, setMediumStock] = useState(true);
    const [largeStock, setLargeStock] = useState(true);
    const [xlargeStock, setXlargeStock] = useState(true);
    const [x2largeStock, setX2largeStock] = useState(true);
    const [x3largeStock, setX3largeStock] = useState(true);

    const gaEventTracker = useAnalyticsEventTracker('Submit Claim Form');

    const getActiveConnector = () => {
        let active;
        Object.keys(connectorsByName).forEach((name) => {
            const currentConnector = connectorsByName[name];
            const connected = currentConnector === connector;
            if (connected) {
                active = name;
            }
        });
        return active;
    };

    const CONTRACT_ADDRESS = "0x00ea2894fe840f105ab99a8f8f75b1f17e94843a";

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            gaEventTracker('submit');
            setError("");
            setIsProcessingSubmit(true);
            let currentConnector = getActiveConnector();
            await API.signMessagePublic(
                context.account,
                currentConnector,
                context
            ).then(async ({ message, signature }) => {
                if (message && signature) {
                    await API.submitTicketForm(
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        tShirtSize,
                        address1,
                        address2,
                        region,
                        city,
                        postalCode,
                        country,
                        context.account,
                        token,
                        CONTRACT_ADDRESS,
                        message,
                        signature
                    ).then((resp) => {
                        if (resp.success === true) {
                            setFailure(false);
                        } else {
                            setIsProcessingSubmit(false);
                            setFailure(true);
                        }
                    });
                }
            });
        } catch (e) {
            console.error(e);
            setError(e.message);
            setIsProcessingSubmit(false);
            setFailure(true);
        } finally {
            setIsProcessingSubmit(false);
            setSubmitted(true);
            // stop loading?
        }
    };

    // useEffect(() => {
    //     API.getSizeStock().then((resp) => {
    //         if (resp['X-Small'] <= 0) setXSmallStock(false);
    //         if (resp['Small'] <= 0) setSmallStock(false);
    //         if (resp['Medium'] <= 0) setMediumStock(false);
    //         if (resp['Large'] <= 0) setLargeStock(false);
    //         if (resp['X-Large'] <= 0) setXlargeStock(false);
    //         if (resp['2X-Large'] <= 0) setX2largeStock(false);
    //         if (resp['3X-Large'] <= 0) setx3largeStock(false);
    //     })
    // }, []);


    const labelClassName = `font-open-sans font-normal text-s font-bold`;
    const inputClassName = `font-open-sans font-normal w-full p-1 rounded-md mb-2 border text-gray-500`;

    return (
        <div className="w-full max-h-[90vh] overflow-y-auto">
            {!isProcessingSubmit && <div
                onClick={() => setIsProcessingModalOpen(false)}
                className='absolute justify-center items-center z-5 flex right-[16px] 
            top-[21px] cursor-pointer h-12 w-12 bg-black/80
            rounded-full hover:bg-black/50'>
                <img className={''} src="/X.svg" alt="close icon" />
            </div>}
            {!submitted ? (
                <div>
                    {!isProcessingSubmit ? (
                        <div className='bg-[#fafafa] p-6 sm:p-10 rounded-xl '>
                            <form onSubmit={handleSubmit}>
                                <h2 className="font-pressio-condensed font-black text-3xl mb-3">Mask Claim Form</h2>
                                <div className="md:grid grid-cols-5 gap-12 lg:gap-20 ">
                                    <div className="col-span-2">
                                        <label>
                                            <p className={labelClassName}>First Name <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="text"
                                                placeholder=""
                                                required
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Last Name <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="text"
                                                placeholder=""
                                                required
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Email <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="email"
                                                placeholder=""
                                                required
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Phone Number <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="number"
                                                placeholder=""
                                                required
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>T-shirt Size <span className="text-red-600">*</span></p>
                                            <select className={inputClassName} defaultValue={''}
                                                onChange={(e) => setTShirtSize(e.target.value)} required>
                                                <option value='' disabled hidden>Choose T-shirt Size</option>
                                                <option hidden={!xsmallStock}>X-Small</option>
                                                <option hidden={!smallStock}>Small</option>
                                                <option hidden={!mediumStock}>Medium</option>
                                                <option hidden={!largeStock}>Large</option>
                                                <option hidden={!xlargeStock}>X-Large</option>
                                                <option hidden={!x2largeStock}>2X-Large</option>
                                                <option hidden={!x3largeStock}>3X-Large</option>

                                            </select>
                                        </label>
                                    </div>
                                    <div className="col-span-3">
                                        <label>
                                            <p className={labelClassName}>Address Line 1 <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="text"
                                                placeholder=""
                                                required
                                                onChange={(e) => setAddress1(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Address Line 2 (Optional)</p>
                                            <input className={inputClassName}
                                                type="text"
                                                placeholder=""
                                                onChange={(e) => setAddress2(e.target.value)}
                                            />
                                        </label>
                                        <div className="md:grid grid-rows-2 grid-cols-2 gap-x-2">
                                            <label>
                                                <p className={labelClassName}>City <span className="text-red-600">*</span></p>
                                                <input className={inputClassName}
                                                    type="text"
                                                    placeholder=""
                                                    required
                                                    onChange={(e) => setCity(e.target.value)}
                                                />
                                            </label>
                                            <label>
                                                <p className={labelClassName}>State / Region <span className="text-red-600">*</span></p>
                                                <input className={inputClassName}
                                                    type="text"
                                                    placeholder=""
                                                    required
                                                    onChange={(e) => setRegion(e.target.value)}
                                                />
                                            </label>
                                            <label>
                                                <p className={labelClassName}>Postal Code <span className="text-red-600">*</span></p>
                                                <input className={inputClassName}
                                                    type="xtet"
                                                    placeholder=""
                                                    required
                                                    onChange={(e) => setPostalCode(e.target.value)}
                                                />
                                            </label>
                                            <label>
                                                <p className={labelClassName}>Country *</p>
                                                <select className={inputClassName} defaultValue={''}
                                                    onChange={(e) => setCountry(e.target.value)} required>
                                                    <option value='' disabled hidden>Select Country</option>
                                                    {Country.getAllCountries().map((country) => (
                                                        <option key={country.name}>{country.name}</option>
                                                    ))}
                                                </select>
                                            </label>

                                        </div>
                                        <div className='flex justify-center mt-4 mb-4 text-[30px]'>
                                            <Button className='' type='submit'>
                                                Claim Now
                                            </Button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    ) : (
                        <div className='bg-[#fafafa] p-10 rounded-xl md:w-96'>
                            <div>
                                <h2 className="font-pressio-condensed font-black text-3xl mb-10">Processing...</h2>
                                <Spinner color="#ebebeb" height={32} width={32} className='' />
                                <div className='mt-10'>
                                    <p className="font-open-sans font-normal">Interact with your wallet and sit tight!</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : !failure ? (
                <div className='bg-[#fafafa] p-10 rounded-xl md:w-96'>
                    <h2 className="font-pressio-condensed font-black text-3xl mb-10">SUCCESS!</h2>
                    <p className=''>
                        Congratulations {`${firstName}`}, you've claimed your Limited Edition Halloween drop.
                    </p>
                    <img className="w-[75%] max-w-[350px] mx-auto my-10 xs:my-14" src={cap} />
                    <p className=''>
                        We will send a confirmation email to {`${email}`} once it's ready to ship.
                    </p>
                </div>
            ) : (
                <div className='bg-[#fafafa] p-10 rounded-xl md:w-96'>
                    <h2 className="font-pressio-condensed font-black text-3xl mb-10">TRY AGAIN!</h2>
                    <p className={``}>
                        Error: {error ? error : ""} - Please try again.
                    </p>
                </div>
            )}
        </div>
    )
}