import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { AiFillCloseCircle } from "react-icons/ai";

import * as AssetAPI from "../utils/utils";
import classNames from "classnames";

export default function NFTs({
    walletAddress,
    onSelect,
    data
}) {
    const [selected, setSelected] = useState([]);

    // const { isLoading, error, data } = useQuery(["nfts", walletAddress], () =>
    //     AssetAPI.retrieveValidTokens(walletAddress, true).then((res) => {
    //         // console.log(`Retrieved Asset Chunk =>`, res);
    //         let temp_Data = [...res, 1, 2, 3, 4, 5];
    //         return temp_Data;
    //         // getTokens(res);
    //         // return res;
    //     })
    // );

    // if (error) return error;

    const getImageUrl = (tokenId) => {
        return AssetAPI.getImageUrl(tokenId);
    };

    function toggleImageSelected(tokenId) {
        let current = [...selected];
        let index = current.indexOf(tokenId);
        if (index >= 0) {
            current.splice(index, 1);
        } else {
            setSelected([]);
            current.push(tokenId);
        }
        // console.log("current", current);
        onSelect(current);
        setSelected(current);
    }

    return (
        <div
            // className="grid grid-cols-2 sm:grid-cols-3 auto-cols-[calc(36%-0.5rem*2)]
            //         md:grid-cols-4 grid-flow-row
            //         gap-2 w-full"
            className="flex flex-row w-fit space-x-4"
        >
            {data &&
                data.map((tokenId) => (
                    <div
                        key={tokenId}
                        className={
                            "cursor-pointer relative rounded-md overflow-hidden h-[84px] w-[84px]"
                        }
                        onClick={() => toggleImageSelected(tokenId)}
                    >
                        <img
                            src={getImageUrl(tokenId)}
                            alt={`Drug receipt #${tokenId}`}
                            className={classNames({
                                " border-4 border-[#FFFFF] opacity-70 brightness-50":
                                    selected.includes(tokenId),
                            })}
                        />
                        <AiFillCloseCircle
                            className={`absolute top-1 right-1 w-8 h-8 text-white opacity-100
                                        ${selected.includes(tokenId)
                                    ? "block"
                                    : "hidden"
                                } `}
                        />
                    </div>
                ))}
        </div>
    );
}
