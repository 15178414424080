import React from "react";
import Form from "./Form";

import { ReactComponent as Close } from "../images/X.svg";
import notificationIconImg from "../images/capload.gif";
import CancelIcon from '@mui/icons-material/Cancel';

export default function ProcessingModal({
    setIsProcessingModalOpen,
    message,
}) {

    return (
        <div
            className="fixed flex flex-wrap justify-center items-center pt-[80px]
        top-0 left-0 bottom-0 -right-0 z-10 
        bg-opacity-60 bg-black backdrop-blur-sm w-screen h-full"
        >
            <div
                className="flex flex-col fixed justify-center items-center p-2
            w-[80%] md:w-[50%] lg:w-[80%] xl:w-[60%] max-w-[460px]  bg-white bg-cover rounded-3xl overflow-y-auto 2xl:overflow-hidden"
            >
                <div
                    onClick={() => { setIsProcessingModalOpen(false) }}
                    className="flex absolute right-[2px] top-[2px] md:top-0 p-3 m-2 
                    justify-center items-center h-8 w-8 rounded-full
                    hover:cursor-pointer transform hover:border-4 text-[#29162D]"
                >
                    <CancelIcon sx={{ width: '28px', height: '28px' }} />
                </div>
                <div className="text-[#29162D] h-full justify-center text-center items-center flex flex-col p-4 py-12">
                    {message == 'Snug yeah! Mint successful.' ? "" : <img src={notificationIconImg} className="mb-[20px]"></img>}
                    <h1 className={'font-Montserrat leading-[32px] '+(message == 'Snug yeah! Mint successful.'?'w-[100%] text-[22px]':'w-[100%] text-[20px]')}>
                        {message}
                    </h1>
                    {message == 'Snug yeah! Mint successful.' ?
                        <div className="mt-[20px] w-full">
                            <a href="https://opensea.io/collection/drx-kill-team-collection" target="_blank" className=" cursor-pointer"><p className="font-open-sans text-white text-[16px] leading-[19px] font-light bg-[#94699E] hover:bg-[#492e4e] w-full text-center p-[14px] rounded-full">View on OpenSea</p></a>
                        </div>:""
                    }
                </div>
            </div>
        </div>
    );
};